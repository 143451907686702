import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useInView } from 'react-intersection-observer';
import { classNames } from '../utils';
import styles from './index.module.scss';
import logo from '../../../assets/images/homepage/logo.svg';
import laptop from '../../../assets/images/homepage/laptop@2x.png';

interface Props extends RouteComponentProps {}

const Header: React.FC<Props> = ({ history }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <div className={classNames([styles.wrapper, isMobile && styles.mobile])}>
      <header className={styles.header}>
        <img src={logo} alt="" width="151" height="26" />
        {!isMobile && (
          <div className={styles.signIn} onClick={() => history.push('/dashboard')}>
            Sign in
          </div>
        )}
      </header>
      <div className={`${styles.lead} ${inView ? styles.inView : ''}`} ref={ref}>
        <div className={styles.inViewFx}>
          <p>Self-sovereign credit score across any blockchain</p>
          <p>Open, Trusted and Innovative</p>
          <p>
            <span className={styles.btn} onClick={() => history.push('/dashboard')}>
              Build My OScore
            </span>
          </p>
        </div>
        {!isMobile && (
          <div className={styles.inViewFx}>
            <img src={laptop} alt="" width="661" height="523" className={styles.laptop} />
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(Header);
