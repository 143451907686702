import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useInView } from 'react-intersection-observer';
import { classNames } from '../utils';
import styles from './index.module.scss';
import logo from '../../../assets/images/homepage/logo.svg';
import arrow from '../../../assets/images/homepage/arrow@2x.png';

interface Props extends RouteComponentProps {}

const Footer: React.FC<Props> = ({ history }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <footer
      className={classNames([styles.wrapper, inView && styles.inView, isMobile && styles.mobile])}
      ref={ref}
    >
      <div className={styles.guideWrapper}>
        <div className={styles.guide}>
          <div className={styles.guideSlogan}>Sign up to check your score now</div>
          <div>
            Already a member?
            <div className={styles.guideSignIn} onClick={() => history.push('/dashboard')}>
              Sign in
              <img src={arrow} alt="" />
            </div>
          </div>
        </div>
      </div>
      <nav className={styles.nav}>
        <img src={logo} alt="" />
        <div>
          <dl className={styles.navItem} style={{ transitionDelay: '.4s' }}>
            <dt>Community</dt>
            <dd>
              <a
                href="https://t.me/OntologyAnnouncements"
                target="_blank"
                rel="noopener noreferrer"
              >
                Telegram
              </a>
            </dd>
          </dl>
          <dl className={styles.navItem} style={{ transitionDelay: '.8s' }}>
            <dt>Support</dt>
            <dd>
              <a
                href="https://github.com/ont-bizsuite/documentation/blob/master/oscore/restapi.md"
                target="_blank"
                rel="noopener noreferrer"
              >
                Documentation
              </a>
            </dd>
            <dd>
              <a
                href="https://github.com/ont-bizsuite/documentation/tree/master/oscore"
                target="_blank"
                rel="noopener noreferrer"
              >
                Github
              </a>
            </dd>
          </dl>
          <dl className={styles.navItem} style={{ transitionDelay: '1.2s' }}>
            <dt>Contact Us</dt>
            <dd>
              <a href="mailto:contact@ont.io">Contact@ont.io</a>
            </dd>
          </dl>
        </div>
      </nav>
      <section className={styles.copyright}>
        <span>© 2020 Ontology — All Rights Reserved</span>
      </section>
    </footer>
  );
};

export default withRouter(Footer);
