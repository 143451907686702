import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useInView } from 'react-intersection-observer';
import { classNames } from '../utils';
import styles from './index.module.scss';

const Step: React.FC = () => {
  const [hoverIndex, setHoverIndex] = useState<Number>(0);
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <section
      className={classNames([styles.wrapper, inView && styles.inView, isMobile && styles.mobile])}
      ref={ref}
    >
      {[
        'The first credit<br /> score for<br /> the decentralized world',
        'OScore will impact<br /> the interest rate and<br /> collateral your<br /> crypto loan',
        'OScore is based on<br /> your crypto<br /> holdings and credit<br /> history',
        'Enjoy extra profit<br /> with a higher<br /> OScore',
      ].map((item, index) => {
        if (isMobile) {
          item = item.replace(/<br\s*\/>/g, '');
        }
        return (
          <div
            className={classNames([styles.box, !isMobile && hoverIndex === index && styles.hover])}
            onMouseEnter={() => setHoverIndex(index)}
            key={index}
          >
            <div
              className={classNames([styles.inViewFx])}
              style={{ transitionDelay: `${0.4 + index * 0.4}s` }}
            >
              <div className={styles.hd}>
                <span>
                  0
                  {index + 1}
                </span>
              </div>
              <div className={styles.bd} dangerouslySetInnerHTML={{ __html: item }} />
            </div>
          </div>
        );
      })}
    </section>
  );
};
export default Step;
