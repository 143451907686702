import React from 'react';

import Header from './Header';
import Step from './Step';
import Slogan from './Slogan';
import Partner from './Partner';
import Footer from './Footer';
import styles from './index.module.scss';

const Homepage: React.FC = () => (
  <div className={styles.wrapper}>
    <Header />
    <Step />
    <Slogan />
    <Partner />
    <Footer />
  </div>
);

export default Homepage;
