export enum CoinType {
  BTC = 'btc',
  ETH = 'eth',
}

export enum CoinTypeLabel {
  BTC = 'BTC',
  ETH = 'ETH',
}

export enum CoinTypeChain {
  BTC = 'Bitcoin',
  ETH = 'Ethereum',
}

export enum LocaleNames {
  ONT = 'ont-id',
  ONT_SIGN = 'ont-signature',
  Token = 'oscore-token',
  JWT_CACHE_NAME = 'USER_JWT_CACHE',
  LOGIN_TYPE = 'USER_LOGIN_TYPE',
  SCORE_CACHE = 'USER_SCORE_CACHE',
  SCORE_ID_CACHE = 'SCORE_ID_CACHE',
  LANG = 'OSCORE_LANG_CACHE',
}

export enum LoginType {
  Cyano = 'Cyano',
  Onto = 'Onto',
}

export enum ImportMethod {
  MetaMask,
  Ledger,
}

export enum CoinPath {
  BTC = '44\'/0\'/0\'/0/0',
  ETH = '44\'/60\'/0\'/0/0',
}

export enum Codes {
  Success = 0,
  CredentialDuplicate = 500,
}

export enum CyanoTags {
  WalletAddressList = 'wallet-list-vc',
}

/**
 * chain enum for api
 */
export enum ChainEnum {
  BTC_CHAIN = 'btc',
  ETH_CHAIN = 'eth',
  ONT_CHAIN = 'ont',
  TRON_CHAIN = 'trx',
  DOT_CHAIN = 'dot',
  KLAY_CHAIN = 'klay',
  NEO_CHAIN = 'neo',
}
