import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './assets/locales/en.json';
import cn from './assets/locales/cn.json';
import { LocaleNames } from './enum';

const resources = {
  en: {
    translation: en,
  },
  cn: {
    translation: cn,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

const cache = localStorage.getItem(LocaleNames.LANG);
if (cache) {
  i18n.changeLanguage(cache);
}

export default i18n;
