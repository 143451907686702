import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';
import { classNames } from '../utils';
import styles from './index.module.scss';
import slogan_1 from '../../../assets/images/homepage/slogan_1@2x.png';
import slogan_2 from '../../../assets/images/homepage/slogan_2@2x.png';
import slogan_3 from '../../../assets/images/homepage/slogan_3@2x.png';

const Slogan: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <section className={classNames([styles.wrapper, inView && styles.inView, isMobile && styles.mobile])} ref={ref}>
      <section>
        {[
          {
            title: 'Build your credit score <br /> for crypto',
            content: 'OScore offers free access to your credit score which is calculated based on your on-chain behaviors and asset statastics. With a higher score, you can get special rates from credit-based DeFi 2.0 platforms and more upcoming decentralized service providers.',
            img: {
              src: slogan_1,
              width: 186,
              height: 165,
            },
          },
          {
            title: 'Monitor Your Crypto Assets',
            content: ` Get instant updates on your held crypto assets stats by connecting wallets and
          exchanges. OScore assures each data access request is made on your own and only for
          your personal user.`,
            img: {
              src: slogan_2,
              width: 186,
              height: 165,
            },
          },
          {
            title: 'Improve score for your credit goals',
            content: 'Start building your OScore for your credit goals, make your every move in DeFi counts. Postive actions such as on time repayment to get a better credit priviliage in the next DeFi you join.',
            img: {
              src: slogan_3,
              width: 161,
              height: 186,
            },
          },
        ].map((item, index) => (
          <div className={styles.box} key={index}>
            <div className={styles.inViewFx} style={{ transitionDelay: `${0.4 + index * 0.4}s` }}>
              <div className={styles.title} dangerouslySetInnerHTML={{ __html: item.title }} />
              <div className={styles.content}>{item.content}</div>
            </div>
            <img
              {...item.img}
              alt=""
              className={styles.inViewFx}
              style={{ transitionDelay: `${0.4 + index * 0.4}s` }}
            />
          </div>
        ))}
      </section>
    </section>
  );
};

export default Slogan;
