import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Routes from './types/routes';
import Homepage from './views/Homepage';

const Layout = lazy(() => import('./layout'));

function App() {
  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route exact path={Routes.Homepage}>
          <Homepage />
        </Route>
        <Route>
          <Layout />
        </Route>
      </Switch>
    </Suspense>
  );
}

export default App;
