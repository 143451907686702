import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';
import { classNames } from '../utils';
import styles from './index.module.scss';
import wing from '../../../assets/images/homepage/logo_wing.svg';
import onto from '../../../assets/images/homepage/logo_onto.svg';

const Partner: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  return (
    <section className={classNames([styles.wrapper, inView && styles.inView, isMobile && styles.mobile])} ref={ref}>
      <div className={styles.hd}>Get OScore from our partners</div>
      <div className={styles.bd}>
        <div className={styles.inViewFx}>
          <div className={styles.box}>
            <img src={wing} alt="wing" height="44" />
            <p>
              A DeFi platform dedicated to the digital asset lending market, supporting cross-chain
              collaboration between various DeFi products.
            </p>
            <a href="https://wing.finance/" target="_blank" rel="noopener noreferrer">
              View More
            </a>
          </div>
        </div>
        <div className={styles.inViewFx}>
          <div className={styles.box}>
            <img src={onto} alt="onto" width="163" height="44" />
            <p>
              Self-sovereign Data Wallet for managing your own digital identities, data and asset.
            </p>
            <a href="https://onto.app/" target="_blank" rel="noopener noreferrer">
              View More
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partner;
