enum Routes {
  Homepage = '/',
  Dashboard = '/dashboard',
  Detail = '/search',
  ManageWallet = '/wallet',
  ManageExchange = '/exchange',
  Certificate = '/cert',
  Publish = '/publish',
  Market = '/market',
  APIDetail = '/apidetail/:apiId'
}

export default Routes;
